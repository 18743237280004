<template>
  <div class="c-page-content h-auto !overflow-hidden pb-1">
    <div class="grid grid-cols-12 mt-5 ml-5">
      <div class="c-transition col-span-12">
        <Card>
          <template #content>
            <div class="mx-4 my-0">
              <div class="flex items-left gap-2 mt-3"></div>
              <WebshopList
                ref="webshopListRef"
                :webshops="webshops"
                :loading="loading"
                @refresh-list="fetchWebshops"
                @open-add-dialog="addDialogVisible = true"
              />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>

  <WebshopAdd v-model:visible="addDialogVisible"></WebshopAdd>
</template>

<script setup lang="ts">
import WebshopAdd from "./WebshopAdd.vue";

const addDialogVisible = ref(false);

const { webshops, fetchWebshops, loading } = useWebshops();
onMounted(fetchWebshops);
</script>
