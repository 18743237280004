<template>
  <div class="c-page-content h-auto !overflow-hidden pb-1">
    <div class="grid grid-cols-12 mt-5 ml-5">
      <div class="c-transition col-span-12">
        <Card>
          <template #title>
            <CompanyToolbar
              v-model:selected-icon="selectedIcon"
              :is-sorting-visible="sortField !== ''"
              @open-sort-list="openSortList"
              @refresh-list="fetchCompanies"
              @open-column-chooser="openColumnChooser"
            />
          </template>
          <template #content>
            <div class="mx-4 my-0">
              <div class="flex items-left gap-2 mt-3"></div>

              <CompanyList
                ref="companyListRef"
                :companies="companies"
                :loading="loading"
                :total-hits="companies.length"
                :page-size="pageSize"
                :page="page"
                :sort-field="sortField"
                :sort-order="sortOrder"
                @update:sort-order="onUpdateSortOrder"
                @update:sort-field="onUpdateSortField"
                @update:page="onUpdatePage"
                @update:page-size="onUpdatePageSize"
                @selected-icon="setSelectedIcon"
              />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const pageSize = ref<number>(50);
const page = ref(1);
const sortField = ref("");
const sortOrder = ref(-1);

const selectedIcon = ref("");
const setSelectedIcon = (value: string) => {
  selectedIcon.value = value;
};

const { loading, companies, fetchCompanies } = useCompanies();

onMounted(fetchCompanies);

const onUpdatePage = async (value: number) => {
  page.value = value;
  loading.value = true;
  await fetchCompanies();
};

const onUpdatePageSize = async (value: number) => {
  pageSize.value = value;
  loading.value = true;
  await fetchCompanies();
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const companyListRef = ref();
const openColumnChooser = (event: Event) => {
  companyListRef.value.openColumnChooser(event);
};

const openSortList = (event: Event) => {
  companyListRef.value.openSortList(event);
};
</script>
