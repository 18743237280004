<template>
  <div>
    <DataTable
      ref="companySearchResult"
      v-model:selection="selectedCompany"
      :value="companies"
      data-key="id"
      :auto-layout="true"
      :lazy="true"
      :paginator="true"
      responsive-layout="scroll"
      selection-mode="single"
      :row-class="addGlobalSearchClass"
      :sort-order="sortOrder"
      :sort-field="sortField"
      removable-sort
      :resizable-columns="true"
      column-resize-mode="fit"
      class="c-datatable"
      striped-rows
      :loading="loading"
      :rows="pageSize"
      data-testid="company-search-table"
      :total-records="totalHits"
      :rows-per-page-options="[50, 100]"
      :current-page-report-template="
        t('common.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      tabindex="0"
      scrollable
      scroll-height="75vh"
      :reorderable-columns="true"
      @row-select="onRowSelected"
      @row-dblclick="onRowDblClicked"
      @sort="onSort"
      @page="onPage"
      @column-reorder="onColumnReorder"
      @column-resize-end="onColumnResizeEnd"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as DataTableColumn[]"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="t(col.header)"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template v-if="col.field == CompanyLinesColumns.SelectColumn" #header>
          <Checkbox binary @update:model-value="(value) => selectAllRows(value)" />
        </template>
        <template #body="{ data, field, index: i }">
          <template v-if="col.field === CompanyLinesColumns.SelectColumn">
            <Checkbox v-model="selectedRows[i]" binary />
          </template>
          <template v-else-if="col.field === CompanyLinesColumns.Name">
            <span>{{ data.name }}</span>
          </template>
          <template v-else-if="col.field === CompanyLinesColumns.OrganizationNumber">
            <span>{{ data.organizationNumber }}</span>
          </template>
          <template v-else-if="col.field === CompanyLinesColumns.PhoneNumber">
            <span>{{ data.phoneNumber }}</span>
          </template>
          <template v-else-if="col.field === CompanyLinesColumns.Active">
            <Badge :severity="data.active ? 'success' : 'warning'">{{
              t(`company.search.${data.active ? "active" : "deactivate"}`)
            }}</Badge>
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>
    </DataTable>

    <ColumnManager
      ref="companyListSortRef"
      v-model:selected-icon="selectedIcon"
      v-model:selected-columns-computed="selectedColumnsComputed"
      v-model:filtered-columns="filteredColumns"
      :search-columns="companySearchColumns"
      :default-sort-field="defaultSortField"
      @on-selected-row="onSelectedRow"
      @reset-columns="resetColumns"
    />
  </div>
</template>
<script setup lang="ts">
import { type LocationQueryValue } from "vue-router";
import { useToast } from "primevue/usetoast";
import type {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { NIL as emptyUuid } from "uuid";
import { useCumulusToast } from "@cumulus/components";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { Company } from "@multicase/portal-api/company";
import { CompanyLinesColumns } from "@/company/models/CompanyLinesColumns";
import ColumnManager from "@/company/components/ColumnManager.vue";

const { t } = useI18n();

const router = useRouter();
const route = useRoute();
const toast = useCumulusToast(useToast());
const companySearchResult = ref();
const selectedCompany = ref<Company>();
const selectedRows = ref<boolean[]>([]);

const selectAllRows = (value: boolean) => {
  for (let i = 0; i < selectedRows.value.length; i++) {
    selectedRows.value[i] = value;
  }
};

const props = defineProps<{
  companies: Company[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:sort", value: string): void;
  (e: "selectedIcon", value: string): void;
}>();

const companyListSortRef = ref();
const openColumnChooser = (event: Event) => {
  companyListSortRef.value.openColumnChooser(event);
};

const openSortList = (event: Event) => {
  companyListSortRef.value.openSortList(event);
};

const selectedCompanys = computed(() => {
  return props.companies.filter((_, index) => selectedRows.value[index]);
});

defineExpose({
  openColumnChooser,
  openSortList,
  selectedCompanys,
});

watch([() => props.companies, () => props.loading], ([companies, loading]) => {
  if (loading === false && companies.length > 0) {
    nextTick(() => {
      selectedRows.value = Array(companies.length).fill(false);
      if (route.query?.focusResult) {
        focusSearchResult(route.query?.focusResult ?? "");
      }
    });
  }
});

const companySearchColumns: DataTableColumn[] = [
  {
    field: "selectColumn",
    header: "company.search.empty",
    class: "w-20",
    sortable: false,
  },
  { field: "name", header: "company.search.header-company-name", sortable: true },
  { field: "email", header: "company.search.header-email", sortable: true },
  { field: "organizationNumber", header: "company.search.header-organization-number", sortable: true },
  { field: "phoneNumber", header: "company.search.header-telephone-number", sortable: true },
  { field: "active", header: "company.search.header-active", sortable: true },
];

const initialSelectedColumns: DataTableColumn[] = [
  {
    field: "selectColumn",
    header: "company.search.empty",
    class: "w-20",
    sortable: false,
  },
  { field: "name", header: "company.search.header-company-name", sortable: true },
  { field: "email", header: "company.search.header-email", sortable: true },
  { field: "organizationNumber", header: "company.search.header-organization-number", sortable: true },
  { field: "phoneNumber", header: "company.search.header-telephone-number", sortable: true },
  { field: "active", header: "company.search.header-active", sortable: true },
];

const { selectedColumnsComputed, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("companySearch", companySearchColumns, initialSelectedColumns);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter((col) => col.field !== CompanyLinesColumns.SelectColumn);
});

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const currentIconIndex = ref(0);
const defaultSortField = ref(companySearchColumns.find((column) => column.field === CompanyLinesColumns.Name));
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const selectedIcon = computed(() => {
  const icon = sortBy[currentIconIndex.value].icon;
  emit("selectedIcon", icon);
  return icon;
});

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult();
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);

    if (defaultSortField.value) {
      emit("update:sortField", defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();
    defaultSortOrder.value = event.sortOrder === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = companySearchColumns.find((column) => column.field === event.sortField);

    emit("update:sortOrder", event.sortOrder ?? 0);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const addGlobalSearchClass = (data: Company) => {
  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedCompany.value?.id === data?.id },
    { "c-inactive": data.active === false },
  ];
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }

  if (event.originalEvent.key === " ") {
    selectedRows.value[event.index] = !selectedRows.value[event.index];
    return false;
  }

  openSearchCompany(event.data.id);
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  openSearchCompany(event.data.id);
};

const openSearchCompany = (companyId: string) => {
  companyId != null && companyId !== emptyUuid
    ? router.push({ name: "company-edit", params: { id: companyId } })
    : toast.add({
        severity: "warn",
        summary: t("company.toast.open-company-failed"),
        detail: t("company.toast.no-company-selected"),
        closable: true,
      });
};

const focusSearchResult = (id: LocationQueryValue[] | string = "") => {
  if (props.companies.length > 0 && companySearchResult.value) {
    const element = id === "" ? null : companySearchResult.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }
    companySearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
</script>

<style scoped lang="scss">
:deep(.c-inactive) td {
  background-color: #fff6f6;
}
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
.c-company-number-text:hover {
  text-decoration: underline;
}
</style>
