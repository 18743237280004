<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <CompanyHeader
      :edit-mode="true"
      :company="company"
      :is-saving="saving"
      :has-updates="isChanged"
      @on-delete="deleteCompany(company.id)"
      @on-save="updateCompany(company)"
      @on-cancel="navigateToSearch"
    />
  </div>

  <div data-testid="edit-product" class="c-page-content mt-3 pr-1">
    <Card class="ml-4">
      <template #content>
        <div class="grid grid-cols-12 gap-8 ml-2">
          <div class="col-span-12 lg:col-span-6">
            <div class="grid grid-cols-12 gap-6">
              <div class="col-span-12 md:col-span-6">
                <CumulusInputText
                  v-model="company.name"
                  :label="t(`company.name.label`)"
                  required
                  test-id="company-name"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusOrgNumber
                  v-model="company.organizationNumber"
                  :label="t(`company.organization-number.label`)"
                  :country-iso="company.address.countryIso"
                  validate
                  test-id="org-number"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusEmail v-model="company.email" :label="t('company.email.label')" validate test-id="email" />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusPhoneNumber
                  v-model="company.phoneNumber"
                  :label="t(`company.telephone-number.label`)"
                  :country-iso="company.address.countryIso"
                  validate
                  test-id="phone-number"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusSelect
                  v-model="company.defaultLanguageIso"
                  :options="languages"
                  :label="t(`company.default-language.label`)"
                  test-id="default-language"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusMultiSelect
                  v-model="company.activeLanguageIsos"
                  :options="languages"
                  :label="t(`company.active-languages.label`)"
                  test-id="active-languages"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <div class="flex justify-start">
                  <span class="mr-2">{{ t("company.registration-date.label") }}: </span>
                  <span data-testid="registered-date">{{ d(company.registrationDate) }}</span>
                </div>
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusCheckbox v-model="company.active" :label="t(`company.active.label`)" test-id="active" />
              </div>
            </div>
          </div>

          <div class="col-span-12 lg:col-span-6">
            <div class="c-company-address grid grid-cols-12 gap-6 content-start">
              <Address
                v-model:address-lines="company.address.addressLines"
                v-model:country-iso="company.address.countryIso"
                v-model:postal-code="company.address.postalCode"
                v-model:city="company.address.city"
              />
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>

  <Card v-if="error" class="m-4">
    <template #content>
      <div>{{ t("common.load-error") }} {{ error }}</div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { useUnsavedChanges } from "@cumulus/components";

const { t, d } = useI18n();
const route = useRoute();
const { languages } = useLanguages();
const { navigateToSearch } = useNavigation("company");
const { company, isChanged, saving, error, fetchCompanyById, updateCompany, deleteCompany } = useCompanies();

const fetchCompany = async () => {
  const id = route.params.id as string;
  await fetchCompanyById(id);
};

onMounted(fetchCompany);

useUnsavedChanges(isChanged);
</script>

<style lang="scss">
.c-company-edit {
  margin: var(--default-content-margin);
}

.c-company-address {
  @media screen and (min-width: 992px) {
    height: 100%;
    margin-left: 0;
    padding-left: 0.5rem;
    border-left: var(--footer-border);
  }
}
</style>
