{
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "minLength": "Minimum length is {min}",
    "exists": "{property} already exists"
  },
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "common": {
    "add": "Add",
    "cancel": "Cancel",
    "create": "Create",
    "delete": "Delete",
    "close": "Close",
    "date": "Date",
    "reload": "Reload",
    "load-error":"Failed to load company, reason :",
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text": "You have unsaved changes. Save your changes or Discard for leaving without saving your changes. Choose Cancel to continue editing",
    "discard": "Discard",
    "save":"Save",
    "message": {
      "validate-error": "Validation failed, correct all red fields and try again"
    },
    "toast": {
      "warn": {
        "summary": "Validation failed",
        "detail": "Correct all red fields and try again"
      }
    },
    "error": "Unknown error",
    "error-occured": "An error occured",
    "error-detail": {
      "unknown-error": "Unknown error",
      "not-found": "Not found",
      "server-error": "Server error",
      "bad-request": "Server cannot process the request"
    },
    "countries": {
      "code": {
        "47": "Norway",
        "826": "The United Kingdom",
        "46": "Sweden",
        "49": "Germany",
        "61": "Australia",
        "55": "Brazil",
        "86": "China",
        "20": "Egypt",
        "33": "France",
        "91": "India",
        "81": "Japan",
        "34": "Spain",
        "1": "USA"
      },
      "iso": {
        "NO": "Norway",
        "GB": "The United Kingdom",
        "DK": "Denmark",
        "FI": "Finland",
        "SE": "Sweden",
        "DE": "Germany",
        "IT": "Italy",
        "FR": "France"
      }
    },
    "address": {
      "add": "Add address",
      "edit": "Edit address",
      "error": "Please enter an address",
      "address-lines": {
        "label": "Address lines",
        "placeholder": "Type in address lines, seperate with enter",
        "error": "At least one address line is required"
      },
      "postal-code-city": {
        "label": "Postal code/city",
        "placeholder-code": "Type in code...",
        "placeholder-city": "Type in city...",
        "error-code": "Postal code is required",
        "error-city": "City is required"
      },
      "postal-code": {
        "error": "Postal code is required"
      },
      "city": {
        "label": "City",
        "placeholder": "Type in city name...",
        "error": "City is required"
      },
      "country": {
        "label": "Country",
        "placeholder": "Choose a country from the list...",
        "error": "Country is required"
      }
    }
  },

  "company": {
    "search-company": "Search Company",
    "add-company" : "Add Company",
    "edit-company": "Edit Company",
    "delete-confirm-message":"Are you sure you want to delete this company?",
    "toast": {
      "reload": {
        "summary": "Reloading company..."
      },
      "success-add": {
        "summary": "New company was added!",
        "detail": "Company name {name}"
      },
      "success-update": {
        "summary": "Company was updated!",
        "detail": "Company name {name}"
      },
      "success-delete": {
        "summary": "Company was deleted!",
        "detail": "Company name {name}"
      },
      "error-delete": {
        "summary": "Could not delete company",
        "detail": "Please try again later"
      },
      "error-update": {
        "summary": "Could not update company",
        "detail": "Please try again later"
      },
      "error-add": {
        "summary": "Could not create company",
        "detail": "Please try again later"
      }
    },
    "name": {
      "label": "Company name",
      "placeholder": "Company name",
      "error": "Company is required"
    },
    "firstName": {
      "label": "First Name",
      "placeholder": "Company Admin First Name",
      "error": "Name is required"
    },
    "lastName": {
      "label": "Last Name",
      "placeholder": "Company Admin Last Name",
      "error": "Name is required"
    },
    "email": {
      "label": "E-mail",
      "placeholder": "E-mail",
      "error" :"E-mail is required"
    },
    "domain": {
      "label": "Domain name",
      "placeholder": "Domain prefix name",
      "error" :"Domain prefix is required"
    },
    "organization-number": {
      "label": "Organization number",
      "placeholder": "Organization number",
      "error" :"Organization number is required"
    },
    "address-country-code": {
      "label": "Country",
      "placeholder": "Country",
      "error" :"Country is required"
    },
    "telephone-number": {
      "label": "Telephone number",
      "placeholder": "Telephone number",
      "error" :"Telephone number is required"
    },
    "address-lines": {
      "label": "Address",
      "placeholder": "Address",
      "error" :"Address is required"
    },
    "postal-code": {
      "label": "Postal code",
      "placeholder": "Postal code",
      "error" :"Postal code is required"
    },
    "city": {
      "label": "City",
      "placeholder": "City",
      "error" :"City is required"
    },
    "registration-date": {
      "label": "Registration date",
      "placeholder": "Registration date",
      "error" :"Registration date is required"
    },
    "active": {
      "label": "Active"
    },
    "country": {
      "label": "Country",
      "placeholder": "Country, sample 'Norway'",
      "error" :"Country is required"
    },
    "countrycode": {
      "placeholder": "Country code, sample '0047'",
      "error" :"Country code is required"
    },
    "country-iso": {
      "placeholder": "Country ISO code, sample 'NO'",
      "error" :"Country ISO code is required"
    },
    "banking": {
      "label": "Bank account name",
      "placeholder": "Bank account name",
      "error" :"Bank account name is required"
    },
    "account-number": {
      "label": "Account number",
      "placeholder": "Account number, sample '9999 99 99999'",
      "error" :"Account number is required"
    },
    "currency": {
      "label": "Currency",
      "placeholder": "Choose a currency from the list..",
      "error" :"Currency is required"
    },
    "confirm-delete": "Are you sure you want to delete this company?",
    "search": {
      "search-label": "Company search:",
      "more-filters": "More filters",
      "total-hits": "{totalHits} companies found",
      "add-btn": "New company",
      "advanced-btn": "Advanced",
      "select-all": "Select all",
      "delete-selected": "Delete selected",
      "edit-columns": "Customize columns",
      "header-company-name": "Company name",
      "header-email": "Email",
      "header-organization-number": "Organization number",
      "header-telephone-number": "Phone number",
      "header-active": "Deactivation date",
      "search-error": "Search failed",
      "empty-list": "No companies found",
      "loading-list": "Loading companies, please wait...",
      "active": "Active",
      "deactivate": "Deactivated"
    },
    "header": {
      "add-company": "Add company",
      "edit-company": "Update company"
    },
    "search-header":{
      "companies":"Companies"
    },
    "search-filters":{
      "columns":"Columns"
    },
    "create-step-1": "1. Company information",
    "create-step-2": "2. B2C configuration",
    "download-b2c-script-btn": "Download B2C script",
    "run-script-label": "Run script",
    "paste-script-result-label": "Paste script result"
  },

  "health-status": {
    "title": "Service status",
    "not-fount": "No States found",
    "loading": "Loading status data. Please wait",
    "column-api-name": "Service",
    "column-product": "Status",
    "column-version": "Version",
    "column-branch": "Branch"
  }
}

