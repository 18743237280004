import { useCompanyApi } from "@multicase/portal-api/company";
import { Company, AddCompanyRequest } from "@multicase/portal-api/company";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { type AuthHeaders } from "@multicase/portal-api";

export function useCompanies() {
  const company = ref<Company>(new Company());
  const companies = ref<Company[]>([]);
  const companyApi = useCompanyApi();

  const { t } = useI18n();
  const toast = useCumulusToast(useToast());
  const { navigateToEdit, navigateToSearch } = useNavigation("company");
  const { getRequest, mutationRequest, loading, saving, error } = useRequests();

  const fetchCompanies = async () => {
    await getRequest(
      async (authHeaders: AuthHeaders) => {
        companies.value = await companyApi.getAll(authHeaders);
      },
      t("common.toast.load-failed.summary", { name: "companies" }),
    );
  };

  const fetchCompanyById = async (id: string) => {
    await getRequest(
      async (authHeaders: AuthHeaders) => {
        company.value = await companyApi.get(authHeaders, id);
        companyUnmodified.value = cloneDeep(company.value);
      },
      t("common.toast.load-failed.summary", { name: "company" }),
    );
  };

  const companyUnmodified = ref<Company>(new Company());
  const isChanged = computed(() => {
    return !isEqual(company.value, companyUnmodified.value);
  });

  const createCompany = async (request: AddCompanyRequest) => {
    if (!(await isFormValid())) {
      return;
    }

    await mutationRequest(
      async (authHeaders: AuthHeaders) => {
        const id = await companyApi.create(authHeaders, request);
        navigateToEdit(id);
      },
      t("common.toast.create-failed.summary", { name: "company" }),
    );
  };

  const updateCompany = async (company: Company) => {
    if (!(await isFormValid())) {
      return;
    }

    await mutationRequest(
      async (authHeaders: AuthHeaders) => {
        await companyApi.update(authHeaders, company);
        companyUnmodified.value = cloneDeep(company);
        navigateToSearch();
      },
      t("common.toast.update-failed.summary", { name: "company" }),
    );
  };

  const deleteCompany = async (id: string) => {
    await mutationRequest(
      async (authHeaders: AuthHeaders) => {
        await companyApi.delete(authHeaders, id);
        navigateToSearch();
      },
      t("common.toast.delete-failed.summary", { name: "company" }),
    );
  };

  const validate = useVuelidate();
  const isFormValid = async (): Promise<boolean> => {
    validate.value.$touch();

    if (!(await validate.value.$validate())) {
      toast.add({
        severity: "warn",
        summary: t("common.toast.warn.summary"),
        detail: t("common.toast.warn.detail"),
        closable: true,
      });
      return false;
    }
    return true;
  };

  return {
    companies,
    company,
    isChanged,
    fetchCompanyById,
    fetchCompanies,
    createCompany,
    updateCompany,
    deleteCompany,
    loading,
    saving,
    error,
  };
}
