<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <CompanyHeader
      :edit-mode="false"
      :is-saving="saving"
      :has-updates="isChanged"
      @on-cancel="navigateToSearch"
      @on-save="createCompany(company)"
    />
  </div>

  <div data-testid="add-product" class="c-page-content mt-1 ml-4 pr-1">
    <Card class="m-2 py-4">
      <template #content>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-6 mx-4">
            <div class="formgrid grid grid-cols-12 gap-6">
              <div class="col-span-12 md:col-span-6">
                <CumulusInputText
                  v-model="company.name"
                  :label="t(`company.name.label`)"
                  required
                  test-id="company-name"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusOrgNumber
                  v-model="company.organizationNumber"
                  :label="t(`company.organization-number.label`)"
                  :country-iso="company.address.countryIso"
                  validate
                  test-id="org-number"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusInputText
                  v-model="company.firstName"
                  :label="t(`company.firstName.label`)"
                  required
                  test-id="first-name"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusInputText
                  v-model="company.lastName"
                  :label="t(`company.lastName.label`)"
                  required
                  test-id="last-name"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusEmail v-model="company.email" :label="t('company.email.label')" validate test-id="email" />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusPhoneNumber
                  v-model="company.phoneNumber"
                  :label="t(`company.telephone-number.label`)"
                  :country-iso="company.address.countryIso"
                  validate
                  test-id="phone-number"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusInputText
                  v-model="company.bankName"
                  :label="t(`company.banking.label`)"
                  required
                  test-id="bank-account-name"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusInputText
                  v-model="company.bankAccountNumber"
                  :label="t(`company.account-number.label`)"
                  required
                  test-id="bank-account-number"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusSelect
                  v-model="company.currencyIso"
                  :options="currencies"
                  :label="t(`company.currency.label`)"
                  test-id="currency"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <CumulusSelect
                  v-model="company.defaultLanguageIso"
                  :options="languages"
                  :label="t(`company.default-language.label`)"
                  test-id="default-language"
                />
              </div>
            </div>
          </div>

          <div class="col-span-12 lg:col-span-6">
            <div class="c-company-address grid grid-cols-12 gap-4 content-start">
              <Address
                v-model:address-lines="company.address.addressLines"
                v-model:country-iso="company.address.countryIso"
                v-model:postal-code="company.address.postalCode"
                v-model:city="company.address.city"
              />
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { useUnsavedChanges } from "@cumulus/components";
import { AddCompanyRequest } from "@multicase/portal-api/company";
import isEqual from "lodash.isequal";

const { t } = useI18n();
const { createCompany, saving } = useCompanies();
const { languages } = useLanguages();
const { currencies } = useCurrencies();
const { navigateToSearch } = useNavigation("company");

const company = ref<AddCompanyRequest>(new AddCompanyRequest());

const isChanged = computed(() => {
  return !isEqual(company.value, new AddCompanyRequest());
});

useUnsavedChanges(isChanged);
</script>

<style lang="scss">
.c-company-add {
  margin: var(--default-content-margin);
}

.c-company-address {
  @media screen and (min-width: 992px) {
    height: 100%;
    margin-left: 0;
    padding-left: 0.5rem;
    border-left: var(--footer-border);
  }
}
</style>
