<template>
  <div class="field col-span-12 md:col-span-6">
    <label for="company-banking" class="c-required"> {{ t(`company.banking.label`) }} </label>

    <InputText
      id="company-banking"
      type="text"
      v-model="name"
      data-testid="company-banking"
      class="inputfield w-full"
      :class="{ 'p-invalid': val.bankingName.$error }"
      :placeholder="t(`company.banking.placeholder`)"
      maxlength="8000"
      @blur="updateName"
    />

    <small class="p-error" v-if="val.bankingName.$error" data-testid="company-banking-error">
      {{ val.bankingName.$errors[0].$message }}
    </small>
  </div>

  <div class="field col-span-12 md:col-span-6">
    <label for="company-banking" class="c-required"> {{ t(`company.account-number.label`) }} </label>

    <InputMask
      mask="9999 99 99999"
      id="company-banking-number"
      v-model="account"
      data-testid="company-banking-number"
      class="inputfield w-full"
      :class="{ 'p-invalid': val.accountNumber.$error }"
      :placeholder="t(`company.account-number.placeholder`)"
      @blur="updateAccount"
    />

    <small class="p-error" v-if="val.accountNumber.$error" data-testid="company-account-number-error">
      {{ val.accountNumber.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const { t } = useI18n();

const props = defineProps<{
  bankingName: string;
  accountNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:bankingName", value: string): void;
  (e: "update:accountNumber", value: string): void;
}>();

const rules = {
  bankingName: {
    required: helpers.withMessage(t("company.banking.error"), required),
  },
  accountNumber: {
    required: helpers.withMessage(
      t("company.account-number.error"),
      (value: string) => value.replace(/[\s_]+/g, "").length >= 11
    ),
  },
};

const val = useVuelidate(rules, props);

const name = ref<string>(props.bankingName ?? "");
watch(
  () => props.bankingName,
  (value) => (name.value = value ?? "")
);
const updateName = () => {
  emit("update:bankingName", name.value);
};

const account = ref<string>(props.accountNumber ?? "");
watch(
  () => props.accountNumber,
  (value) => (account.value = value ?? "")
);
const updateAccount = () => {
  emit("update:accountNumber", account.value.replace(/[\s_]+/g, ""));
};
</script>
