<template>
  <WebshopToolbar
    :is-chooser-visible="isChooserVisible"
    @refresh-list="emit('refreshList')"
    @open-add-dialog="emit('openAddDialog')"
    @open-column-chooser="openColumnChooser"
  />
  <DataTable
    ref="webshopSearchResult"
    :value="webshops"
    data-key="id"
    :auto-layout="true"
    :paginator="true"
    responsive-layout="scroll"
    :resizable-columns="true"
    selection-mode="single"
    column-resize-mode="fit"
    class="c-datatable"
    :loading="loading"
    :rows="pageSize"
    :sort-field="sortField"
    :sort-order="sortOrder"
    removable-sort
    data-testid="webshop-search-table"
    :rows-per-page-options="[50, 100]"
    :current-page-report-template="
      t('common.current-page-template', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    tabindex="0"
    scrollable
    scroll-height="75vh"
    :reorderable-columns="true"
    @row-click="onRowClicked"
    @column-resize-end="onColumnResizeEnd"
    @column-reorder="onColumnReorder"
  >
    <Column
      v-for="(col, index) of selectedColumnsComputed"
      :key="col.field + '_' + index"
      :field="col.field"
      :header="t(col.header)"
      :class="col.class"
      :pt="{
        headerCell: {
          id: col.field,
        },
      }"
      :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
    >
      <template #body="{ data, field }">
        <template v-if="col.field === WebshopLinesColumns.DomainName">
          <span>{{ data[field as keyof typeof data] }}</span>
        </template>
        <template v-else-if="col.field === WebshopLinesColumns.Active">
          <Badge :severity="data[field as keyof typeof data] ? 'success' : 'warning'">
            {{ t(`webshop.active.state-${data[field as keyof typeof data] ? "active" : "inactive"}`) }}
          </Badge>
        </template>
        <template v-else>
          <span>{{ data[field as keyof typeof data] }}</span>
        </template>
      </template>
    </Column>
    <template #empty>
      <div class="p-4 text-center">
        <span>{{ t("webshop.not-found") }}</span>
      </div>
    </template>
  </DataTable>

  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selected-columns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @reset-columns="resetColumns"
  />
</template>
<script setup lang="ts">
import type { DataTableRowClickEvent } from "primevue/datatable";
import type { Webshop } from "../models/Webshop";
import { PopOverColumnChooser, useTablePreferences, type DataTableColumn } from "@cumulus/components";
import { WebshopLinesColumns } from "@/webshop/models/WebshopLinesColumns";

const { t } = useI18n();
const sortField = ref("");
const sortOrder = ref(-1);

defineProps<{
  webshops: Webshop[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "refreshList"): void;
  (e: "openAddDialog"): void;
}>();

const pageSize = ref(50);

const webshopSearchColumns: DataTableColumn[] = [
  { field: "domainName", header: "webshop.name.label", sortable: false },
  { field: "integrationType", header: "webshop.integration-type.label", sortable: false },
  { field: "defaultLanguageIso", header: "webshop.default-language.label", sortable: false },
  { field: "active", header: "webshop.active.label", sortable: false },
];

const { selectedColumnsComputed, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("discountSearch", webshopSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const { navigateToEdit } = useNavigation("webshop");
const onRowClicked = (event: DataTableRowClickEvent) => {
  navigateToEdit(event.data.id);
};

const chooserRef = ref();

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

defineExpose({
  openColumnChooser,
});
</script>

<style scoped lang="scss"></style>
