<template>
  <div v-if="useTeleport" id="company-navbar-shortcuts">
    <Teleport to="#c-navbar-title">
      <h1>{{ editMode ? t("company.header.edit-company") : t("company.header.add-company") }}</h1>
    </Teleport>
  </div>

  <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12">
    <Button
      id="btn-cancel"
      size="small"
      variant="text"
      data-testid="btn-cancel"
      :disabled="isSaving"
      @click="$emit('onCancel')"
    >
      <i class="pi pi-arrow-left"></i>
      <span class="px-2">{{
        !editMode ? t("company.new-company") : company?.organizationNumber + " - " + company?.name
      }}</span>
    </Button>
  </div>

  <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

  <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
    <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
      <Button
        id="btn-save"
        class="c-circular-button mr-4"
        data-testid="btn-save"
        :disabled="!hasUpdates || isSaving"
        @click="emit('onSave')"
      >
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ editMode ? t("common.save") : t("common.create") }}</span>
      </Button>
    </div>

    <Button v-if="editMode" class="c-circular-button mr-4" data-testid="btn-delete" @click="onConfirmDelete">
      <i class="pi pi-trash c-delete-button c-circular-icon"></i>
      <span class="px-4">{{ t("common.delete") }}</span>
    </Button>
  </div>

  <ConfirmPopup data-testid="confirm-delete"></ConfirmPopup>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";
import { Company } from "@multicase/portal-api/company";

const { t } = useI18n();

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");

const confirm = useConfirm();
const props = defineProps<{
  isSaving: boolean;
  editMode: boolean;
  hasUpdates: boolean;
  company?: Company;
}>();

const emit = defineEmits<{
  (e: "onCancel"): void;
  (e: "onDelete"): void;
  (e: "onSave"): void;
}>();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("company.delete-confirm-message"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      emit("onDelete");
    },
  });
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>
