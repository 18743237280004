<template>
  <div class="c-company-search" v-if="loadFailed">
    <Card class="m-4">
      <template #content>
        <div>{{ t("common.load-error") }} {{ errorReason }}</div>
      </template>
    </Card>
  </div>
  <CompanyToolbar />
  <div class="c-company-search" v-if="!loadFailed">
    <Card class="m-4">
      <template #content>
        <DataTable
          :value="filteredCompanies"
          v-model:expandedRows="expandedRows"
          dataKey="id"
          :autoLayout="true"
          responsiveLayout="scroll"
          selectionMode="single"
          @row-select="onRowSelect"
          class="c-datatable"
          :loading="loading"
        >
          <Column
            v-for="(col, index) of selectedColumns.filter((c) => c.field !== 'active')"
            :field="col.field"
            :header="col.header"
            :key="col.field + '_' + index"
          >
          </Column>

          <Column
            :header="t('company.search.header-active')"
            class="c-col-image"
            v-if="selectedColumns.findIndex((c) => c.field === 'active') !== -1"
          >
            <template #body="{ data }">
              <Badge :severity="data.active ? 'success' : 'warning'">{{
                t(`company.search.${data.active ? "active" : "deactivate"}`)
              }}</Badge>
            </template>
          </Column>

          <template #empty>{{ t("company.search.empty-list") }} </template>
          <template #loading>{{ t("company.search.loading-list") }}</template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { DataTableRowSelectEvent } from "primevue/datatable";
import { DataTableColumn } from "@/repositories/company/model/search/DataTableColumn";
import { Company } from "@/repositories/company/model/Company";
import { useCompany } from "@/repositories/company/CompanyService";

import CompanyToolbar from "./CompanyToolbar.vue";

const { t } = useI18n();
const router = useRouter();
const totalHits = ref(0);
const errorReason = ref("");
const loading = ref(false);
const loadFailed = ref(false);
const companies = ref<Array<Company>>([]);
const filteredCompanies = ref<Company[]>([]);
const expandedRows = ref([]);
const { getAllCompanies } = useCompany();

const columns = ref<DataTableColumn[]>([
  { field: "companyName", header: t("company.search.header-company-name") },
  { field: "email", header: t("company.search.header-email") },
  { field: "organizationNumber", header: t("company.search.header-organization-number") },
  { field: "telephoneNumber", header: t("company.search.header-telephone-number") },
  { field: "active", header: t("company.search.header-active") },
] as DataTableColumn[]);

const selectedColumns = ref<DataTableColumn[]>(columns.value);

const onRowSelect = (event: DataTableRowSelectEvent) => {
  if (event.type == "row") {
    router.push({ name: "company-edit", params: { id: event.data.id } });
  }
};

const loadcompanies = async () => {
  loading.value = true;
  try {
    companies.value = await getAllCompanies();

    filteredCompanies.value = companies.value;
    totalHits.value = companies.value.length;
  } catch (error) {
    loadFailed.value = true;
  } finally {
    loading.value = false;
  }
};

onMounted(loadcompanies);
</script>

<style scoped lang="scss">
.c-company-search {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}

:deep(.p-datatable) .p-datatable-tbody > tr {
  height: 2.5rem;
}
</style>
