<template>
  <label for="company-telephone-number" class="c-required"> {{ t(`company.telephone-number.label`) }} </label>

  <InputMask
    mask="999 99 999"
    id="company-telephone-number"
    v-model="telephoneNumberValue"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.telephoneNumber.$error }"
    :placeholder="t(`company.telephone-number.placeholder`)"
    maxlength="15"
    @blur="updateTelephoneNumber"
  />

  <small class="p-error" v-if="val.telephoneNumber.$error" data-testid="company-telephone-number-error">
    {{ val.telephoneNumber.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";

const { t } = useI18n();

const props = defineProps<{
  telephoneNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:telephoneNumber", value: string): void;
}>();

const rules = {
  telephoneNumber: {
    required: helpers.withMessage(
      t("company.telephone-number.error"),
      (value: string) => value.replace(/[\s_]+/g, "").length >= 8
    ),
  },
};

const val = useVuelidate(rules, props);

const telephoneNumberValue = ref<string>(props.telephoneNumber ?? "");
watch(
  () => props.telephoneNumber,
  (value) => (telephoneNumberValue.value = value ?? "")
);
const updateTelephoneNumber = () => {
  emit("update:telephoneNumber", telephoneNumberValue.value.replace(/[\s_]+/g, ""));
};
</script>
