import { Address } from "./Address";

export class Company {
  id = "";
  name = "";
  email = "";
  registrationDate = "";
  organizationNumber = "";
  phoneNumber = "";
  address: Address = new Address();
  active = false;
}
