<template>
  <div v-if="useTeleport" id="webshop-navbar-shortcuts">
    <Teleport to="#c-navbar-title">
      <h1>{{ "webshop.header.edit-webshop" }}</h1>
    </Teleport>
  </div>

  <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
    <Button
      id="btn-save"
      class="c-circular-button mr-4"
      data-testid="btn-save"
      :disabled="!hasUpdates || isSaving"
      @click="emit('onSave')"
    >
      <i class="pi pi-check c-success-button c-circular-icon"></i>
      <span class="px-4">{{ t("common.save") }}</span>
    </Button>
  </div>

  <Button class="c-circular-button mr-4" data-testid="btn-delete" @click="onConfirmDelete">
    <i class="pi pi-trash c-delete-button c-circular-icon"></i>
    <span class="px-4">{{ t("common.delete") }}</span>
  </Button>

  <ConfirmPopup data-testid="webshop-delete-confirm"></ConfirmPopup>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";

const { t } = useI18n();

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");

const confirm = useConfirm();
const props = defineProps<{
  isSaving: boolean;
  hasUpdates: boolean;
}>();

const emit = defineEmits<{
  (e: "onCancel"): void;
  (e: "onDelete"): void;
  (e: "onSave"): void;
}>();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("webshop.delete-confirm-message"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      emit("onDelete");
    },
  });
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>
