<template>
  <Dialog v-model:visible="visible" modal header="Add webshop" :style="{ width: '30rem' }">
    <span class="text-surface-500 dark:text-surface-400 block mb-8"
      >You will be able to set up more detailed information in the next step.</span
    >

    <div class="mb-4">
      <CumulusInputText v-model="request.domainName" :label="t(`webshop.name.label`)" required />
    </div>
    <div class="mb-4">
      <CumulusInputText
        v-model="request.apiUrl"
        :label="t(`webshop.domain.label`)"
        :helptext="t('webshop.domain.helptext')"
        required
      />
    </div>
    <div v-show="clients.length > 1" class="mb-4">
      <CumulusSelect
        v-model="request.clientId"
        :options="clients"
        :loading="loadingClients"
        :label="t('webshop.client.label')"
        :default-select-first="true"
      />
    </div>
    <div v-show="warehouses.length > 1" class="mb-4">
      <CumulusSelect
        v-model="request.warehouseId"
        :options="warehouses"
        :loading="loadingWarehouses"
        :label="t('webshop.warehouse.label')"
        :default-select-first="true"
      />
    </div>
    <div class="mb-4">
      <CumulusSelect
        v-model="request.integrationType"
        :options="integrationTypes"
        :label="t('webshop.integration-type.label')"
      />
    </div>
    <div class="mb-8">
      <CumulusSelect
        v-model="request.defaultLanguageIso"
        :options="languages"
        :label="t(`webshop.default-language.label`)"
      />
    </div>

    <div class="flex justify-end gap-2">
      <Button type="button" label="Cancel" severity="secondary" @click="visible = false"></Button>
      <Button type="button" label="Save" @click="createWebshop(request)"></Button>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { IntegrationType } from "../models/WebshopIntegrationType";
import { AddWebshopRequest } from "../api/WebshopApi";

const { t } = useI18n();

const visible = defineModel<boolean>("visible", { default: false });

const { clients, loading: loadingClients } = useClients();
const { warehouses, loading: loadingWarehouses } = useWarehouses();
const integrationTypes = ref<{ id: string; name: string }[]>([
  { id: IntegrationType.WooCommerce, name: IntegrationType.WooCommerce },
  { id: IntegrationType.Smartstore, name: IntegrationType.Smartstore },
]);
const { languages } = useLanguages();

const request = ref<AddWebshopRequest>(new AddWebshopRequest());

const { createWebshop } = useWebshops();
</script>
