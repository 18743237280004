<template>
  <div v-if="useTeleport" id="company-navbar-shortcuts">
    <Teleport to="#c-navbar-title">
      <h1>{{ t("company.search-header.companies") }}</h1>
    </Teleport>
  </div>

  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button class="mr-8 text-sm" variant="text" size="small" @click="router.push({ name: 'company-add' })">
        <i class="pi pi-plus c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.add") }}</span>
      </Button>
    </div>

    <div>
      <Button
        v-tooltip.bottom="{
          value: t('company.toolbar.sorting'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="btn-selected-icon"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': props.isSortingVisible }"
        @click="emit('openSortList', $event)"
      >
        <i :class="selectedIcon"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('company.toolbar.refresh'),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('company.toolbar.column-chooser'),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="btn-column-chooser"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('openColumnChooser', $event)"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
const router = useRouter();
const { t } = useI18n();

const props = defineProps<{
  isSortingVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "openSortList", value: Event): void;
  (e: "refreshList"): void;
  (e: "openColumnChooser", value: Event): void;
}>();

const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");
</script>
