import { Warehouse, useWarehouseApi } from "@multicase/portal-api/warehouse";
import { type AuthHeaders } from "@multicase/portal-api";

export function useWarehouses() {
  const warehouses = ref<Warehouse[]>([]);

  const { getRequest, error, loading } = useRequests();
  const warehouseApi = useWarehouseApi();

  const fetchWarehouses = async () => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      warehouses.value = await warehouseApi.getAll(authHeaders);
    });
  };

  onMounted(fetchWarehouses);

  return { warehouses, loading, error };
}
