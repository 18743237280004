{
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "minLength": "{property} lengde er {min}",
    "exists": "{property} finnes fra før"
  },
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "common": {
    "add": "Legg til",
    "cancel": "Avbryt",
    "create": "Opprett",
    "delete": "Slett",
    "close": "Lukk",
    "date": "Dato",
    "reload": "Last inn på nytt",
    "load-error":"Kunne ikke laste inn Firma, grunn ::",
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text": "Du har endringer som ikke er lagret. Lagre dine endringene eller Forkast for å forlate uten å lagre. Velg Avbryt for a fortsette redigeringen",
    "discard":"Forkast",
    "save":"Lagre",
    "message": {
      "validate-error": "Validering feilet, rett alle røde felter og prøv igjen"
    },
    "toast": {
      "warn": {
        "summary": "Validering feilet",
        "detail": "Rett alle rød fleter og prøv igjen"
      }
    },
    "error": "Ukjent feil",
    "error-occured": "En feil oppstod",
    "error-detail": {
      "unknown-error": "Ukjent feil",
      "not-found": "Ikke funnet",
      "server-error": "Server feil",
      "bad-request": "Server kan ikke behandle forespørselen"
    },
    "countries": {
      "code": {
        "47": "Norge",
        "826": "Storbritania",
        "46": "Sverige",
        "49": "Tyskland",
        "61": "Australia",
        "55": "Brasil",
        "86": "Kina",
        "20": "Egypt",
        "33": "Frankriket",
        "91": "India",
        "81": "Japan",
        "34": "Spania",
        "1": "USA"
      },
      "iso": {
        "NO": "Norge",
        "GB": "Storbritania",
        "DK": "Danmark",
        "FI": "Finland",
        "SE": "Sverige",
        "DE": "Tyskland",
        "IT": "Italia",
        "FR": "Frankriket"
      }
    },
    "address": {
      "add": "Legg til adresse",
      "edit": "Rediger adresse",
      "error": "Vennligst skriv inn en adresse",
      "address-lines": {
        "label": "Adresselinjer",
        "placeholder": "Skriv inn adresselinjer, skill med enter",
        "error": "Minst en adresselinje er påkrevet"
      },
      "postal-code-city": {
        "label": "Postnr./sted",
        "placeholder-code": "Skriv inn postnr...",
        "placeholder-city": "Skriv inn by...",
        "error-code": "Postkode er påkrevet",
        "error-city": "By er påkrevet"
      },
      "postal-code": {
        "error": "Postkode er påkrevet"
      },
      "city": {
        "label": "By",
        "placeholder": "Skriv inn navn på byen...",
        "error": "By er påkrevet"
      },
      "country": {
        "label": "Land",
        "placeholder": "Velg et land fra listen...",
        "error": "Land er påkrevet"
      }
    }
  },

  "company": {
    "search-company": "Søk Firma",
    "add-company" : "Legg Til Firma",
    "edit-company": "Endre Firma",
    "delete-confirm-message":"Er du sikker på at du vil slette dette firma?",
    "toast": {
      "reload": {
        "summary": "Laster inn firma..."
      },
      "success-add": {
        "summary": "Nytt firma ble lagt til!",
        "detail": "Firmanavn {name}"
      },
      "success-update": {
        "summary": "Firmaet ble oppdatert!",
        "detail": "Firmanavn {name}"
      },
      "success-delete": {
        "summary": "Firmaet ble slettet!",
        "detail": "Firmanavn {name}"
      },
      "error-delete": {
        "summary": "Kunne ikke slette firma",
        "detail": "Vennligst prøv igjen senere"
      },
      "error-update": {
        "summary": "Kunne ikke oppdatere firma",
        "detail": "Vennligst prøv igjen senere"
      },
      "error-add": {
        "summary": "Kunne ikke opprette firma",
        "detail": "Vennligst prøv igjen senere"
      }
    },
    "firstName": {
      "label": "Fornavn",
      "placeholder": "Firma adminstrator fornavn",
      "error": "Fornavn er påkrevd"
    },
    "lastName": {
      "label": "Etternavn",
      "placeholder": "Firma adminstrator etternavn",
      "error": "Etternavn er påkrevd"
    },
    "name": {
      "label": "Firmanavn",
      "placeholder": "Firmanavn",
      "error": "Firmanavn er påkrevd"
    },
    "email": {
      "label": "E-post",
      "placeholder": "E-post",
      "error" :"E-post er påkrevd"
    },
    "domain": {
      "label": "Domenenavn",
      "placeholder": "Domene prefiks for portalen",
      "error" :"Domene prefiks er påkrevd"
    },
    "organization-number": {
      "label": "Organisasjonsnummer",
      "placeholder": "Organisasjonsnummer",
      "error" :"Organisasjonsnummer er påkrevd"
    },
    "address-country-code": {
      "label": "Land",
      "placeholder": "Land",
      "error" :"Land er påkrevd"
    },
    "telephone-number": {
      "label": "Telefonnummer",
      "placeholder": "Telefonnummer",
      "error" :"Telefonnummer er påkrevd"
    },
    "address-lines": {
      "label": "Adresse",
      "placeholder": "Adresse",
      "error" :"Adresse er påkrevd"
    },
    "postal-code": {
      "label": "Postnummer",
      "placeholder": "Postnummer",
      "error" :"Postnummer er påkrevd"
    },
    "city": {
      "label": "By",
      "placeholder": "By",
      "error" :"By er påkrevd"
    },
    "registration-date": {
      "label": "Registreringsdato",
      "placeholder": "Registreringsdato",
      "error" :"Registreringsdato er påkrevd"
    },
    "active": {
      "label": "Aktiv"
    },
    "country": {
      "label": "Land",
      "placeholder": "Land, eksempel 'Norge'",
      "error" :"Land er påkrevd"
    },
    "countrycode": {
      "placeholder": "Land kode, eksempel '0047'",
      "error" :"Land kode er påkrevd"
    },
    "country-iso": {
      "placeholder": "ISO land kode, eksempel 'NO'",
      "error" :"ISO land kode er påkrevd"
    },
    "banking": {
      "label": "Bankkontonavn",
      "placeholder": "Bankkontonavn",
      "error" :"Navn på bankkontoen er påkrevd"
    },
    "account-number": {
      "label": "Bankkontonummer",
      "placeholder": "Bankkontonummer, eksempel '9999 99 99999'",
      "error" :"Bankkontonummer er påkrevd"
    },
    "currency": {
      "label": "Valuta",
      "placeholder": "Velg en valuta fra listen..",
      "error" :"Valuta er påkrevd"
    },
    "confirm-delete": "Er du sikker på at du vil slette firma?",
    "search": {
      "search-label": "Firma søk:",
      "more-filters": "Flere filtre",
      "total-hits": "{totalHits} firma funnet",
      "add-btn": "Ny ansatt",
      "advanced-btn": "Avansert",
      "select-all": "Velg alle",
      "delete-selected": "Slett valgte",
      "edit-columns": "Tilpass kolonner",
      "header-company-name": "Firmanavn",
      "header-email": "E-post",
      "header-organization-number": "Organisasjonsnummer",
      "header-telephone-number": "Telefonnummer",
      "header-active": "Aktiv",
      "search-error": "Søk feilet",
      "empty-list": "Ingen firma funnet",
      "loading-list": "Laster inn firma, vennligst vent...",
      "active": "Aktiv",
      "deactivate": "Deaktivert"
    },
    "header": {
      "add-company": "Legg til firma",
      "edit-company": "Oppdater firma"
    },
    "search-header":{
      "companies":"Firma"
    },
    "search-filters":{
      "columns":"kolonner"
    },
    "create-step-1": "1. Bedriftsinformasjon",
    "create-step-2": "2. B2C konfigurasjon",
    "download-b2c-script-btn": "Last ned B2C script",
    "run-script-label": "Kjør script",
    "paste-script-result-label": "Lim inn script resultat"
  },

  "health-status": {
    "title": "Tjenestestatus",
    "not-fount": "Ingen statuser funnet",
    "loading": "Laster inn statusdata. Vennligst vent",
    "column-api-name": "Tjeneste",
    "column-product": "Status",
    "column-dependencies": "Avhengigheter",
    "column-version": "Versjon",
    "column-branch": "Gren"
  }
}
