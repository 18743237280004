import { useAuth, loginRequest, InteractionRequiredAuthError } from "@cumulus/event-bus";
import { type AuthHeaders } from "@multicase/portal-api";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";

export function useRequests() {
  const loading = ref<boolean>(false);
  const saving = ref<boolean>(false);
  const error = ref<unknown | null>(null);

  const { getAuthHeaders, instance } = useAuth();

  const getRequest = async <T>(
    fn: (authHeaders: AuthHeaders) => Promise<T>,
    errorMessage: string = "",
  ): Promise<T | undefined> => {
    loading.value = true;
    error.value = null;

    try {
      const authHeaders = await getAuthHeaders();
      return await fn(authHeaders);
    } catch (e) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest);
        return;
      }

      if (errorMessage && errorMessage.length > 0) {
        showToast(errorMessage, getErrorMessage(e));
      }

      error.value = e;
      console.error(e);
    } finally {
      loading.value = false;
    }
  };

  const mutationRequest = async (fn: (authHeaders: AuthHeaders) => Promise<void>, errorMessage: string = "") => {
    saving.value = true;
    error.value = null;

    try {
      const authHeaders = await getAuthHeaders();
      return await fn(authHeaders);
    } catch (e) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest);
        return;
      }

      if (errorMessage && errorMessage.length > 0) {
        showToast(errorMessage, getErrorMessage(e));
      }

      error.value = e;
      console.error(e);
    } finally {
      saving.value = false;
    }
  };

  const getErrorMessage = (error: unknown): string => {
    if (hasMessage(error)) {
      return error.message;
    }

    if (hasErrors(error)) {
      return Object.values(error.message.errors).flat().join(", ");
    }

    return "Unknown error";
  };

  function hasMessage(x: unknown): x is { message: string } {
    return Boolean(typeof x === "object" && x && "message" in x && typeof x.message === "string");
  }

  function hasErrors(x: unknown): x is { message: object & { errors: object } } {
    return Boolean(
      typeof x === "object" &&
        x &&
        "message" in x &&
        typeof x.message === "object" &&
        x.message &&
        "errors" in x.message &&
        typeof x.message.errors === "object",
    );
  }

  const toast = useCumulusToast(useToast());
  const showToast = (summary: string = "", detail: string = "") => {
    toast.add({
      severity: "error",
      summary: summary,
      detail: detail,
    });
  };

  return {
    loading,
    saving,
    error,
    getRequest,
    mutationRequest,
  };
}
