import { AddWebshopRequest, UpdateApiCredentialsRequest, UpdateWebshopRequest, webshopApi } from "../api/WebshopApi";
import { Webshop } from "../models/Webshop";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { type AuthHeaders } from "@multicase/portal-api";

export function useWebshops() {
  const webshop = ref<Webshop>(new Webshop());
  const webshops = ref<Webshop[]>([]);

  const { t } = useI18n();
  const toast = useCumulusToast(useToast());
  const { navigateToEdit, navigateToSearch } = useNavigation("webshop");
  const { getRequest, mutationRequest, loading, saving, error } = useRequests();

  const fetchWebshops = async () => {
    await getRequest(
      async (authHeaders: AuthHeaders) => {
        webshops.value = await webshopApi.getAll(authHeaders);
      },
      t("common.toast.load-failed.summary", { name: "webshops" }),
    );
  };

  const fetchWebshopById = async (id: string) => {
    await getRequest(
      async (authHeaders: AuthHeaders) => {
        webshop.value = await webshopApi.get(authHeaders, id);
        webshopUnmodified.value = cloneDeep(webshop.value);
      },
      t("common.toast.load-failed.summary", { name: "webshop" }),
    );
  };

  const webshopUnmodified = ref<Webshop>(new Webshop());
  const isChanged = computed(() => {
    return !isEqual(webshop.value, webshopUnmodified.value);
  });

  const createWebshop = async (request: AddWebshopRequest) => {
    if (!(await isFormValid())) {
      return;
    }

    await mutationRequest(
      async (authHeaders: AuthHeaders) => {
        const id = await webshopApi.create(authHeaders, request);
        navigateToEdit(id);
      },
      t("common.toast.create-failed.summary", { name: "webshop" }),
    );
  };

  const updateWebshop = async (webshop: Webshop) => {
    if (!(await isFormValid())) {
      return;
    }

    await mutationRequest(
      async (authHeaders: AuthHeaders) => {
        const request = new UpdateWebshopRequest();
        request.domainName = webshop.domainName;
        request.clientId = webshop.clientId;
        request.defaultLanguageIso = webshop.defaultLanguageIso;
        request.warehouseId = webshop.warehouseId;
        request.integrationType = webshop.integrationType;
        request.active = webshop.active;
        await webshopApi.update(authHeaders, webshop.id, request);
        const credRequest = new UpdateApiCredentialsRequest();
        credRequest.apiUrl = webshop.apiUrl;
        credRequest.authUsername = webshop.authUsername;
        credRequest.authPassword = webshop.authPassword;
        await webshopApi.updateApiCredentials(authHeaders, webshop.id, credRequest);
        webshopUnmodified.value = cloneDeep(webshop);
        navigateToSearch();
      },
      t("common.toast.update-failed.summary", { name: "webshop" }),
    );
  };

  const deleteWebshop = async (id: string) => {
    await mutationRequest(
      async (authHeaders: AuthHeaders) => {
        await webshopApi.delete(authHeaders, id);
        navigateToSearch();
      },
      t("common.toast.delete-failed.summary", { name: "webshop" }),
    );
  };

  const updateProducts = async (webshopId: string) => {
    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await webshopApi.updateProducts(authHeaders, webshopId);

      toast.add({
        severity: "success",
        summary: t("webshop.toast.update-products.summary"),
        detail: t("webshop.toast.update-products.detail"),
      });
    });
  };

  const validate = useVuelidate();
  const isFormValid = async (): Promise<boolean> => {
    validate.value.$touch();

    if (!(await validate.value.$validate())) {
      toast.add({
        severity: "warn",
        summary: t("common.toast.warn.summary"),
        detail: t("common.toast.warn.detail"),
      });
      return false;
    }
    return true;
  };

  return {
    webshops,
    webshop,
    isChanged,
    fetchWebshopById,
    fetchWebshops,
    createWebshop,
    updateWebshop,
    deleteWebshop,
    updateProducts,
    loading,
    saving,
    error,
  };
}
