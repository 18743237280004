<template>
  <div class="col-span-12 md:col-span-12 ml-6 mr-4">
    <CumulusAddressLines
      v-model="addressLines"
      :label="t('common.address.address-lines.label')"
      required
      :test-id="testIdPrefix ? `${testIdPrefix}-address-lines` : 'address-lines'"
    />
  </div>
  <div class="col-span-12 md:col-span-12 ml-6 mr-4">
    <CumulusSelect
      v-model="countryIso"
      :options="countryOptions"
      :label="t('common.address.country.label')"
      :test-id="testIdPrefix ? `${testIdPrefix}-address-country` : 'address-country'"
    />
  </div>

  <div class="col-span-12 md:col-span-12 ml-6 mr-4">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 md:col-span-4">
        <CumulusInputText
          v-model="postalCode"
          :label="t('common.address.postal-code-city.label')"
          required
          :test-id="testIdPrefix ? `${testIdPrefix}-address-postal-code` : 'address-postal-code'"
          @change="onPostalCodeChange"
        />
      </div>
      <div class="col-span-12 md:col-span-8">
        <CumulusInputText
          v-model="city"
          :label="t('common.address.postal-code-city.label')"
          required
          :test-id="testIdPrefix ? `${testIdPrefix}-address-city` : 'address-city'"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const countryIso = defineModel<string>("countryIso", { required: true });
const addressLines = defineModel<string[]>("addressLines", { required: true });
const postalCode = defineModel<string>("postalCode", { required: true });
const city = defineModel<string>("city", { required: true });

defineProps<{
  testIdPrefix?: string;
}>();

const { countries, fetchCityByPostalCode } = useCountries();
const countryOptions = computed(() => countries.value.map((c) => ({ id: c.iso, name: c.name })));

const onPostalCodeChange = async (code: string) => {
  if (code.trim() === "") {
    city.value = "";
  } else {
    const result = await fetchCityByPostalCode(code);
    if (result && result.length > 0) {
      city.value = result;
    }
  }
};
</script>
