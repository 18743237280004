import { type Client, useClientApi } from "@multicase/portal-api/client";
import { type AuthHeaders } from "@multicase/portal-api";

export function useClients() {
  const clients = ref<Client[]>([]);

  const { getRequest, error, loading } = useRequests();
  const clientApi = useClientApi();

  const fetchClients = async () => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      clients.value = await clientApi.getAll(authHeaders);
    });
  };

  onMounted(fetchClients);

  return { clients, loading, error };
}
