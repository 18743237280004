<template>
  <Card class="c-health-status c-card-list" data-testid="card-health-status">
    <template #title>{{ t("health-status.title") }}</template>
    <template #content>
      <DataTable
        :value="healths"
        class="c-datatable c-table-health"
        dataKey="id"
        :autoLayout="true"
        :loading="loading"
        responsiveLayout="stack"
        selectionMode="single"
        stripedRows
      >
        <template #empty> {{ t("health-status.not-fount") }} </template>
        <template #loading> {{ t("health-status.loading") }} </template>

        <Column class="c-column-api-name" field="id" :header="t('health-status.column-api-name')" />

        <Column class="c-column-product" field="results" :header="t('health-status.column-product')">
          <template #body="{ data }">
            <Accordion value="0">
              <AccordionPanel value="0">
                <AccordionHeader
                  ><div class="c-column-product-tab-header">
                    <Tag
                      class="tag-product-status"
                      :rounded="true"
                      :severity="data.status == 'Healthy' ? 'success' : 'danger'"
                      value=""
                    >
                      <i
                        class="pi"
                        :class="{
                          'pi-check': data.status == 'Healthy',
                          'pi-times':
                            data.status == 'Unavailable' ||
                            data.status == 'Unhealthy' ||
                            data.status == 'Degraded' ||
                            data.status == null,
                        }"
                      ></i>
                    </Tag></div
                ></AccordionHeader>
                <AccordionContent>
                  <ul class="dependencies-list">
                    <li class="flex justify-between flex-wrap" v-for="result in data.results" :key="result.status">
                      <div class="flex items-end justify-center c-dependency-status-text">
                        {{ result.dependency }}
                      </div>

                      <div class="flex items-center justify-center c-dependency-status-icon">
                        <Tag
                          class="tag-product-status"
                          :rounded="true"
                          :severity="result.status == 'Healthy' ? 'success' : 'danger'"
                          value=""
                        >
                          <i
                            class="pi"
                            :class="{
                              'pi-check': result.status == 'Healthy',
                              'pi-times':
                                result.status == 'Unavailable' ||
                                data.status == 'Unhealthy' ||
                                data.status == 'Degraded' ||
                                data.status == null,
                            }"
                          ></i>
                        </Tag>
                      </div>
                    </li>
                  </ul>
                </AccordionContent>
              </AccordionPanel>
            </Accordion>
          </template>
        </Column>
        <Column class="c-column-version" field="version" :header="t('health-status.column-version')"> </Column>
        <Column class="c-column-branch" field="branch" :header="t('health-status.column-branch')"> </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import CumulusStatusService from "./services/StatusService";
import { Health } from "./services/dto/StatusHealth";
import { useAuth } from "@cumulus/event-bus";

const { getAuthHeaders } = useAuth();

const { t } = useI18n();
const loading = ref(true);
const healths = ref<Health[]>();

const load = async () => {
  const authHeaders = await getAuthHeaders();
  CumulusStatusService.getStatusForAllServices(authHeaders)
    .then((data) => {
      if (data != null) {
        healths.value = data;
      }
    })
    .catch(async (error) => {
      // eslint-disable-next-line no-console
      console.error("Status list: ", error);
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(load);
</script>

<style lang="scss">
.c-health-status {
  margin: var(--default-content-margin);
}

.c-column-api-name {
  word-break: break-word;
}
.c-column-product {
  .p-tag.p-tag-rounded {
    min-height: 1.9rem;
  }
}
.c-column-version {
  word-break: break-word;
}
.c-column-branch {
  word-break: break-word;
}

.dependencies-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .dep-status {
    padding-right: 1rem;
    padding-bottom: 0.5rem;
  }

  li {
    padding: 0.5rem;
    font-weight: 500;
    .c-dependency-status-text {
      padding-bottom: 0.2rem;
    }
  }

  li:nth-child(odd) {
    background: #dee2e6;
  }
}

@media (max-width: 991px) {
  .c-column-product {
    span.p-column-title {
      display: none !important;
    }
    .p-accordion.p-component {
      display: block !important;
      width: 100%;
    }

    .c-column-product-tab-header {
      text-align: right;
      width: 100%;
    }
  }
}

@media (min-width: 992px) {
  .c-column-product-tab-header {
    text-align: right;
    width: 50%;
  }
}
</style>
