import { webshopApi } from "../api/WebshopApi";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { type AuthHeaders } from "@multicase/portal-api";

export function useWooCommerce() {
  const toast = useCumulusToast(useToast());
  const { mutationRequest, loading, saving, error } = useRequests();

  const createWebhooks = async (webshopId: string, isLocal: boolean) => {
    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await webshopApi.createWebhooks(authHeaders, webshopId, isLocal);

      toast.add({
        severity: "success",
        summary: "Webhooks created",
        detail: "Webhooks have been created successfully.",
      });
    });
  };

  const deleteWebhooks = async (webshopId: string) => {
    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await webshopApi.deleteWebhooks(authHeaders, webshopId);

      toast.add({
        severity: "success",
        summary: "Webhooks deleted",
        detail: "Webhooks have been deleted successfully.",
      });
    });
  };

  return {
    createWebhooks,
    deleteWebhooks,
    loading,
    saving,
    error,
  };
}
